.main-wrapper {
    height: 60vh;
    margin: 10px 50px;
    filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
    margin-top: 15px;
}

.map-details {
    text-align: center;
    font-size: 1.2em;
}

.map-details span {
    font-weight: bold;
}

.search-input {
    font-size: 14px;
    width: 80%;
}
/* 
#current_user{
    position:absolute;
    bottom:12px;
    right:12px;
    width:160px;
    z-index: 999;
  
  }
  #new_user video{
    height: calc(100vh - 53px);
    width: 100%;
    object-fit: cover;
  } */