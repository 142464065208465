.slide-but * {
  box-sizing: border-box;
}
.slide-but {
  border-radius: 6px;
  min-width: 200px;
  padding: 11px;
  position: relative;
  overflow: hidden;
  color: #98A0AC;
    font-family:'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  user-select: none;
  /* box-sizing: border-box; */
}
.slide-but * {
  pointer-events: none;
  user-select: none;
}
.slide-but .slide-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 2rem;
  background: #444;
  transition: width  ease-out;
  will-change: width;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-but .slide-overlay .slide-overlay-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.slide-but .slide-overlay .slide-caret-wrapper {
  position: absolute;
  max-width: 45px;
  min-width: 0;
  right: 0;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);
  background: #444;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}
.slide-but .slide-overlay .slide-overlay-txt {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: #fff;;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family:"IBMPlexSans-All-SemiBold"
}
.my-class1 {
  border-radius: 6px;
  border-color: none !important;
  background:#EEF9F2 !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;

}
.my-caret-class1 {
  background:#5AC782 !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;

}
.my-overlay-class1 {
  background:#5AC782 !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;
}
.my-class2 {
  border-radius: 6px;
  border-color: none !important;
  background:#FFF4EB !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;
}
.my-caret-class2 {
  background:#EF822F !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;
}
.my-overlay-class2 {
  background:#EF822F !important;
  font-family: "IBMPlexSans-All-SemiBold" !important;
}